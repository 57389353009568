.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 12px 60px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
}

  .subMenu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 10px;
    z-index: 1001;
    line-height: 24px;
  }

.nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}
.iconBasket {
  transition: 0.3s ease; 
  top: 10px !important;
  position: relative;
}

.iconBasket:hover {
 transform: scale(1.1);
}
.counter {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: #000;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navList {
    display: flex;
    list-style: none;
    margin: 0;
}

.navItem {
    margin-left: 3rem;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}
.navItemSubMenu {
  text-align: start;
  padding-bottom: 10px;
}

.navItem a {
    text-decoration: none;
    color: #545454;
    font-size: 1rem;
}
.navItem a:hover {
    color: #ff0000;
}

.hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
.buttonFechar {
    display: none;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  .menuHamburguerItemContainer {
    display: flex;
    align-items: center; 
    margin-top: 20px;
  }
  @media (min-width: 768px) {

    .contato{
      margin-top: 20px;
    }
    
  }
  /* Contêiner para o ícone */
  .iconContainer {
    margin-right: 10px; /* Espaçamento entre o título e o ícone */
  }
  
  /* Estilo do ícone */
  .iconMais {
    cursor: pointer; /* Para mostrar que é clicável */
  }

@media (max-width: 768px) {
  .counter {
    position: absolute;
    right: 64px;
    background-color: #000;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .buttonFechar {
        display: block;
    }
    .hamburger {
        display: block;
    }
    .nav {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 70%;
        max-width: 300px;
        background-color: #fff;
        display: none;
        padding-top: 2rem;
        z-index: 1000;
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
      }
  
      .navOpen {
        display: flex;
        justify-content: center;
        padding: 15px;
        overflow: auto;
      }
      
      .navList {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 30px;
      }
      
      .navItem {
        margin-left: 2rem;
      }
      
      .navItem a {
        text-decoration: none;
        color: #333;
        font-size: 1rem;
      }
      
      .navItem a:hover {
        color: #ff0000;
      }
}


