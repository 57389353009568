.container {
    display: flex;
    justify-content: space-around;
    margin-right: 50px;
    padding: 20px;
    flex-direction: row;
    text-align: center;
    
}

.mobile {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.li {
    list-style: none;
    text-align: center;
}
.LiMobile {
    list-style: none;
    text-align: center;
    padding: 15px;
}


h3 {
    font-size: medium;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    color: #424242;
}

.titleNIcon {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.vertLine {
    border-left: 1px solid #9A9A9A;
    height: 80px;
}

a.contactLink {
    text-decoration: none;
    font-size: large;
    font-weight: normal;
    color: #000;
    cursor: pointer;
}

a.contactLink:hover {
    background-color: #ff0000;
    padding: 4px;
    border-radius: 10px;
    color: #fff;
}
